export enum MODAL_EVENTS {
  SELECT_SIZE = "select-size",
  CUSTOM_FEATURES = "custom-features",
  EDIT_LISTING = "edit-listing",
  REMOVE_LISTING = "remove-listing",
  APPROVE_LISTING = "approve-listing",
  SIGNUP_DISCLAIMER = "signup-disclaimer",
  REQUEST_REVIEW = "request-review",
  VARIABLE_PRICING = "variable-pricing",
  VERIFY_EMAIL = "verify-email",

  TERMS_AND_CONDITIONS = "terms-and-conditions",
  ABOUT_US = "about-us",
  PRIVACY_POLICY = "privacy-policy",
  CONTACT_US = "contact-us",
  BOOK_LISTING = "book-listing",

  FILTER = "filter",
}

export enum ALERT_EVENTS {
  ERROR = "error",
  SUCCESS = "success",
  INFO = "info",
}

export enum DASHBOARD_EVENTS {
  TOGGLE_NAV = "toggle-nav",
}
