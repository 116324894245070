import type { AppProps } from "next/app";
import "../src/styles/globals.scss";
import '@/src/styles/timepicker.css'
import { Toaster } from "@/src/components/ui/toaster";
import AuthProvider from "@/src/components/layouts/authProvider";
import { SessionProvider } from "next-auth/react";
import GlobalProvider from "../src/components/layouts/GlobalProvider";

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
    <GlobalProvider>
      <Component {...pageProps}/>
      
    </GlobalProvider>
    </>
  );
}
