import { type ClassValue, clsx } from "clsx";
import moment from "moment";
import { twMerge } from "tailwind-merge";
import { bookings } from "../signals/userBookings";
import { inquiries } from "../signals/userInquiries";
import { savedListing } from "../signals/userSavedListings";
import { userChatRooms } from "../signals/userChatRooms";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function to<T>(d: any): T {
  return d;
}

export function removeUndefined<T>(obj: T): T {
  for (const propName in obj) {
    if (obj[propName] === "undefined" || obj[propName] === undefined) {
      delete obj[propName];
    }
    if (obj[propName] === "null" || obj[propName] === null) {
      delete obj[propName];
    }

    if (typeof obj[propName] === "object") {
      removeUndefined(obj[propName]);
    }

    if (Array.isArray(obj[propName])) {
      if (obj[propName].length === 0) {
        delete obj[propName];
      } else
        obj[propName].forEach((item, index) => {
          if (typeof item === "object") {
            removeUndefined(item);
          }
        });
    }
  }
  return obj;
}
export const formatTime = (time: any) => {
  if (!time || typeof time !== 'string') return 'Invalid Time'; // Handle undefined, null, or non-string values

  const [hours, minutes] = time.split(':');
  if (!hours || !minutes) return 'Invalid Time'; // Ensure split was successful

  const period = +hours >= 12 ? 'PM' : 'AM';
  const formattedHours = +hours % 12 || 12; // Convert to 12-hour format
  return `${formattedHours}:${minutes} ${period}`;
};


export function nestObject(flatObject: any) {
  const nestedObject = {} as any;

  for (const key in flatObject) {
    const keys = key.split(/[\[\]]+/).filter(Boolean); // Split by brackets and remove empty strings
    let current = nestedObject;

    for (let i = 0; i < keys.length; i++) {
      const part = keys[i];
      if (i === keys.length - 1) {
        // If it's the last part, set the value
        current[part] = flatObject[key];
      } else {
        // Otherwise, create an empty object if it doesn't exist
        current[part] = current[part] || {};
        current = current[part];
      }
    }
  }

  return nestedObject;
}

export function formatNumber(number: number | string) {
  return Number(number).toLocaleString();
}

export type dateType = string | { day: number; month: number; year: number };

export type timeType= {hours:number, minutes:number, seconds?:number}

const toMomentDate = (date: dateType) => {
  if (typeof date === "string") {
    return moment(date);
  } else if (date && typeof date === "object") {
    return moment(`${date.year}-${date.month}-${date.day}`, "YYYY-MM-DD");
  } else {
    // console.error("Invalid date type:", date);
    // throw new Error("Invalid date type");
    return moment();
  }
};

export const calscDaysSpent = (startDate: dateType, endDate: dateType) => {
  const start =
    typeof startDate === "string" ? new Date(startDate) : new Date(startDate.year, startDate.month - 1, startDate.day)
  const end = typeof endDate === "string" ? new Date(endDate) : new Date(endDate.year, endDate.month - 1, endDate.day)

  const daysBooked = Math.ceil((end.getTime() - start.getTime()) / (1000 * 3600 * 24)) + 1
  const daysSpent = Math.ceil((new Date().getTime() - start.getTime()) / (1000 * 3600 * 24)) + 1

  return { daysBooked, daysSpent }
}

export const formatDuration = (startDate: dateType, endDate: dateType) => {
  if (!startDate || !endDate) return ""

  // Convert dates to Date objects if they're not already
  const start =
    typeof startDate === "string"
      ? new Date(startDate)
      : new Date(
          (startDate as { year: number; month: number; day: number }).year,
          (startDate as { year: number; month: number; day: number }).month - 1,
          (startDate as { year: number; month: number; day: number }).day,
        )

  const end =
    typeof endDate === "string"
      ? new Date(endDate)
      : new Date(
          (endDate as { year: number; month: number; day: number }).year,
          (endDate as { year: number; month: number; day: number }).month - 1,
          (endDate as { year: number; month: number; day: number }).day,
        )

  // Calculate the difference in milliseconds
  const diffTime = Math.abs(end.getTime() - start.getTime())

  // Calculate days, months, and years
  const totalDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

  if (totalDays < 30) {
    // Less than 30 days: show in days
    return `${totalDays} ${totalDays === 1 ? "day" : "days"}`
  } else if (totalDays < 365) {
    // Between 30 days and 1 year: show in months and days
    const months = Math.floor(totalDays / 30)
    const days = totalDays % 30

    let result = `${months} ${months === 1 ? "month" : "months"}`
    if (days > 0) {
      result += `, ${days} ${days === 1 ? "day" : "days"}`
    }
    return result
  } else {
    // More than 1 year: show in years, months, and days
    const years = Math.floor(totalDays / 365)
    const remainingDays = totalDays % 365
    const months = Math.floor(remainingDays / 30)
    const days = remainingDays % 30

    let result = `${years} ${years === 1 ? "year" : "years"}`
    if (months > 0) {
      result += `, ${months} ${months === 1 ? "month" : "months"}`
    }
    if (days > 0) {
      result += `, ${days} ${days === 1 ? "day" : "days"}`
    }
    return result
  }
}

export const durationByMonths = (startDate: dateType, endDate: dateType) => {
  const duration = moment.duration(toMomentDate(endDate).diff(toMomentDate(startDate)));
  return Math.floor(duration.asMonths());
};

export function bookingDuration(startDate: dateType, endDate: dateType): string {
  const getMonthAbbreviation = (month: number) => {
    return moment().month(month - 1).format("MMM");
  };

  if (typeof startDate === "string" && typeof endDate === "string") {
    const start = moment(startDate);
    const end = moment(endDate);
    const totalMonths = end.diff(start, "months", true);
    const formattedOutput = `${start.format("D MMM")} → ${end.format("D MMM")} • ${Math.ceil(totalMonths)} months`;
    return formattedOutput;
  } else if (typeof startDate === "object" && typeof endDate === "object") {
    const start = new Date(startDate.year, startDate.month - 1, startDate.day);
    const end = new Date(endDate.year, endDate.month - 1, endDate.day);
    
    const yearDiff = end.getFullYear() - start.getFullYear();
    const monthDiff = end.getMonth() - start.getMonth();
    const dayDiff = end.getDate() - start.getDate();
    
    let totalMonths = yearDiff * 12 + monthDiff;
    if (dayDiff >= 0) {
        totalMonths++;
    }

    const formattedOutput = `${startDate.day} ${getMonthAbbreviation(startDate.month)} → ${endDate.day} ${getMonthAbbreviation(endDate.month)} • ${totalMonths} months`;
    return formattedOutput;
  } else {
    console.error("Invalid date type:", startDate, endDate);
    throw new Error("Invalid date type");
  }
}

export function formatAmount(amount: number | string): string {
  // Ensure the input is a string for regex processing
  const numStr = amount.toString();

  // Use regex to add commas as thousand separators
  return numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export const daysAgo = (date: Date | string): string => {
  const inputDate = typeof date === 'string' ? new Date(date) : date;
  if (isNaN(inputDate.getTime())) {
    return "Invalid date";
  }

  const now = new Date();
  const diffMs = now.getTime() - inputDate.getTime();
  const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));

  if (diffDays === 0) {
    return "Today";
  } else if (diffDays === 1) {
    return "Yesterday";
  } else if (diffDays > 1 && diffDays <= 7) {
    return `${diffDays} days ago`;
  } else {
    // Format as short date (e.g., "Feb 19")
    return inputDate.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
    });
  }
};


export const fetchBookings=()=>{
  return bookings.value
}
export const fetchBooking=(id:string)=>{
 return bookings.value.find(booking=>booking.bookingId==id)
}

export const fetchUserInquiries=()=>{
  return inquiries.value
}

export const fetchSavedListings=()=>{
  return savedListing.value
}

export const fetchChatRooms=()=>{
  return userChatRooms.value
}



