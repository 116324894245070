import React, { ReactElement } from "react";
import { types } from "util";
import { Loader } from '@googlemaps/js-api-loader';

const GOOGLE_MAPS_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_API_KEY?? '';

const loader = new Loader({
  apiKey: GOOGLE_MAPS_API_KEY,
  version: 'weekly',
  libraries: ['places'], // Include the Places library for Autocomplete
});

let googleMapsPromise: Promise<typeof google> | null = null;

export function loadGoogleMaps(): Promise<typeof google> {
  if (typeof window === 'undefined') {
    return Promise.reject(new Error('Google Maps API cannot be loaded on the server'));
  }

  if (window.google && window.google.maps) {
    return Promise.resolve(window.google);
  }

  if (!googleMapsPromise) {
    googleMapsPromise = loader.load().then(() => window.google);
  }

  return googleMapsPromise;
}

export function generateAngle() {
  // Generate a random integer between 0 and 11 (inclusive)
  const randomMultiple = Math.floor(Math.random() * 12);

  // Multiply the random multiple by 30 to get the angle in degrees
  const angle = randomMultiple * 30;

  return angle;
}

export function selectRandomIndex(array: any[]) {
  // Check for empty array to avoid errors
  if (!array.length) {
    throw new Error("Cannot select from an empty array.");
  }

  // Generate a random integer within the array's length
  const randomIndex = Math.floor(Math.random() * array.length);

  return randomIndex;
}

export const getElem = (query: string) => {
  return document.querySelector(query) as HTMLElement;
};

export function simpleHash(str1: string, str2: string) {
  const combined = str1 + str2;
  let hash = 0;
  for (let i = 0; i < combined.length; i++) {
    const char = combined.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32-bit integer
  }
  return Math.abs(hash).toString(36);
}

export const ChildrenWithProps = (
  Children: React.ReactNode,
  extraProps: any
) => {
  return React.Children.map(Children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, extraProps);
    }
    return child;
  });
};

export const CallElementWithProps = (element: JSX.Element, extraProps: any) => {
  return React.cloneElement(element, extraProps);
};

// src/lib/functions.ts

export async function generateAddressOptions(
  address: string,
  restrictToCountry = false
): Promise<Array<{ value: string; text: string; meta: { placeId: string } }>> {
  // Wait for Google Maps API to load
  const google = await loadGoogleMaps();

  const sessionToken = new google.maps.places.AutocompleteSessionToken();
  const autocompleteService = new google.maps.places.AutocompleteService();

  return new Promise((resolve, reject) => {
    const request: google.maps.places.AutocompletionRequest = {
      input: address,
      sessionToken,
      ...(restrictToCountry
        ? {
            types: ['address'],
            componentRestrictions: { country: 'ng' },
            locationBias: new google.maps.LatLngBounds(
              new google.maps.LatLng(6.3969, 3.3550), // SW Lagos
              new google.maps.LatLng(6.5464, 3.4345)  // NE Lagos
            ),
          }
        : {
            locationBias: 'IP_BIAS',
            types: ['geocode'],
          }),
    };

    autocompleteService.getPlacePredictions(
      request,
      (predictions: google.maps.places.AutocompletePrediction[] | null, status: string) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
          resolve(
            predictions.map((prediction) => ({
              value: prediction.description,
              text: prediction.description,
              meta: { placeId: prediction.place_id },
            }))
          );
        } else if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
          resolve([]);
        } else {
          reject(new Error(`Address search failed: ${status}`));
        }
      }
    );
  });
}


export async function generateLocalityOptions(
  input: string,
  restrictToNigeria = true
): Promise<Array<{ value: string; text: string; meta: { placeId: string } }>> {
  // Wait for Google Maps API to load
  const google = await loadGoogleMaps();

  const sessionToken = new google.maps.places.AutocompleteSessionToken();
  const autocompleteService = new google.maps.places.AutocompleteService();

  return new Promise((resolve, reject) => {
    const request: google.maps.places.AutocompletionRequest = {
      input: input,
      sessionToken,
      types: ['(cities)'], // Focus on localities (cities, towns, etc.)
      ...(restrictToNigeria
        ? {
            componentRestrictions: { country: 'ng' }, // Restrict to Nigeria
            locationBias: new google.maps.LatLngBounds(
              new google.maps.LatLng(4.0691, 2.6769), // SW Nigeria
              new google.maps.LatLng(13.8856, 14.6780) // NE Nigeria
            ),
          }
        : {
            locationBias: 'IP_BIAS',
          }),
    };

    autocompleteService.getPlacePredictions(request, (predictions, status) => {
      if (status !== google.maps.places.PlacesServiceStatus.OK || !predictions) {
        reject(new Error(`Places API error: ${status}`));
        return;
      }

      const options = predictions.map((prediction) => ({
        value: prediction.description,
        text: prediction.description,
        meta: {
          placeId: prediction.place_id,
        },
      }));

      resolve(options);
    });
  });
}


export function objectToFormData(
  obj: any,
  form = new FormData(),
  namespace = ""
) {
  for (let property in obj) {
    if (obj.hasOwnProperty(property)) {
      const formKey = namespace ? `${namespace}[${property}]` : property;

      // Check if the property is an object or an array, and recursively append its fields
      if (
        typeof obj[property] === "object" &&
        !(obj[property] instanceof File)
      ) {
        // If it's a non-file object, recurse, but if it's a File, treat it as a FormData field
        objectToFormData(obj[property], form, formKey);
      } else {
        // If it's a primitive (string, number, or File), append it to the FormData
        form.append(formKey, obj[property]);
      }
    }
  }
  return form;
}

export function base64ToFile(base64String: string, fileName: string) {
  // Split the base64 string to get the content type and data
  const [mimeInfo, base64Data] = base64String.split(",");
  const mimeType = mimeInfo.match(/:(.*?);/)?.[1]; // Extract MIME type

  // Decode base64 to raw binary data held in a string
  const byteCharacters = atob(base64Data);

  // Create an array buffer to hold the binary data
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  // Convert the array buffer to a blob
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: mimeType });

  // Create a File object from the Blob (File interface has extra properties like name)
  return new File([blob], fileName, { type: mimeType });
}

export const delay = async (time: number): Promise<void> => {
  return new Promise((resolve) => {
    const id = setTimeout(() => {
      resolve();
      clearTimeout(id);
    }, time);
  });
};

export const paramsToObject = (url = window.location.search) => {
  const params = new URLSearchParams(url);
  const pairs = Array.from(params.entries());
  const pairsObj = pairs.reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {} as Record<string, string>);
  return pairsObj;
};

export function paramsFromObject(payload: any) {
  const params = new URLSearchParams();

  function buildParams(obj: any, parentKey: string) {
    if (obj === null || obj === undefined) {
      return;
    }

    if (typeof obj === "object" && !Array.isArray(obj)) {
      Object.keys(obj).forEach((key) => {
        const fullKey = parentKey ? `${parentKey}[${key}]` : key;
        buildParams(obj[key], fullKey);
      });
    } else if (Array.isArray(obj)) {
      obj.forEach((value, index) => {
        const fullKey = `${parentKey}[${index}]`;
        buildParams(value, fullKey);
      });
    } else {
      params.append(parentKey, obj);
    }
  }

  buildParams(payload, "");

  return params.toString();
}

export function on<T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  ...args: Parameters<T["addEventListener"]> | [string, Function | null, ...any]
): void {
  if (obj && obj.addEventListener) {
    obj.addEventListener(
      ...(args as Parameters<HTMLElement["addEventListener"]>)
    );
  }
}

export function off<T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  ...args:
    | Parameters<T["removeEventListener"]>
    | [string, Function | null, ...any]
): void {
  if (obj && obj.removeEventListener) {
    obj.removeEventListener(
      ...(args as Parameters<HTMLElement["removeEventListener"]>)
    );
  }
}
