import {configureStore} from '@reduxjs/toolkit'
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux'
import { authSlice } from './auth';
import { userSlice } from './users';
import { listingSlice } from './listings';
import { BookingSlice } from './bookings';
import { messageSlice } from './listings/messageSlice';
import { utilSLice } from './utils';




 export const store = configureStore({
    reducer:{
        auth:authSlice.reducer,
        user:userSlice.reducer,
        utils:utilSLice.reducer,
        chats:messageSlice.reducer,
        booking:BookingSlice.reducer,
        listing:listingSlice.reducer
    }
})

export const useAppDispatch:()=> typeof store.dispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<ReturnType<typeof store.getState>> = useSelector;