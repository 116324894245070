import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FilterState, ListingResponse, ListingsResponse, publicListingsResponse,   } from "./interface";
import { LISTING_VERIFICATION_STATUS } from "./features";


interface initialState {
    listing:ListingResponse|null,
    listings:ListingsResponse|null
    publicListings:publicListingsResponse|null
    filters: FilterState;
    search: string
    isFilterActive: boolean;
    
}

const initialState:initialState ={
    listing:null,
    listings:null,
    publicListings:null,
    filters: {
      location: "",
      rating: undefined,
      use_cases: [],
      amenities: [],
      price: { from: "100", to: "100000" },
      size: { from: "400", to: "700" },
      distance: "10",
      verification_status: LISTING_VERIFICATION_STATUS.APPROVED,
    },
    search: "",
    isFilterActive: false,
    
}

export const listingSlice = createSlice({
    name:'listing',
    initialState,
    reducers:{
       setListing:(state:initialState, action:PayloadAction<ListingResponse>)=>{
        state.listing = action.payload

       },
       setListings:(state:initialState, action:PayloadAction<ListingsResponse>)=>{
        state.listings = action.payload
       },
       setFilters: (state, action: PayloadAction<FilterState>) => {
        state.filters = action.payload
        state.isFilterActive = Object.keys(action.payload).length > 0
      },
  
      // Update a single filter field
      updateFilter: (state, action: PayloadAction<{ key: keyof FilterState; value: any }>) => {
        const { key, value } = action.payload
        state.filters[key] = value
        state.isFilterActive = true
      },
  
      // Update search query
      setSearchQuery: (state, action: PayloadAction<string>) => {
        state.search= action.payload
      },
  
      // Reset filters to initial state
      resetFilters: (state) => {
        state.filters = initialState.filters
        state.isFilterActive = false
      },
  
      // Initialize filters from URL or other source
      initializeFilters: (state, action: PayloadAction<FilterState>) => {
        state.filters = {
          ...initialState.filters,
          ...action.payload,
        }
        state.isFilterActive = Object.keys(action.payload).length > 0
      },
       setPublicListings:(state:initialState, action:PayloadAction<publicListingsResponse>)=>{
        state.publicListings = action.payload
       } 
    }

})



export const {
    setListing,
    setListings,
    setFilters, updateFilter, setSearchQuery, resetFilters, initializeFilters,
    setPublicListings} = listingSlice.actions