import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { bookingResponse, BookingsResponse } from "./interface";


interface inititalStateInterface {
    Bookings:BookingsResponse|null
    Booking:bookingResponse|null
}

const initialState:inititalStateInterface ={
    Bookings:null,
    Booking:null
}

export const BookingSlice = createSlice({
    name:'Booking',
    initialState,
    reducers:{
        setBookings:(state:inititalStateInterface, action:PayloadAction<BookingsResponse>)=>{
            state.Bookings = action.payload
        },
        setBooking:(state:inititalStateInterface, action:PayloadAction<bookingResponse>)=>{
            state.Booking = action.payload
        }
    }
})

export const {
    setBooking,
    setBookings
} = BookingSlice.actions