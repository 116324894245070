import { signal } from "@preact/signals-react";

//Replace with actual data from API and try to map it into this format, if that will be convenient for you.
export const savedListing= signal(
    [
        {
           listingId: "wq612627ws",
           name: "Large open space for meets",
           location:"Banana Island Lagos",
           price:30000,
           image:{
            url:"/details-image-1.jpeg",
            alt: "An image of a listing in myblankspace"
           },
        },
        {
           listingId: "161761gsags",
           name: "Large open space for meets",
           location:"Banana Island Lagos",
           price:45000,
           image:{
            url:"/details-image-2.jpeg",
            alt: "Another image of a listing in myblankspace"
           },
         
        },
    ]
)
