import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import { signInCredentials } from './interface'


interface initialState {
    credentials:signInCredentials|null
}

const initialState ={
 credentials:null
}

export const authSlice = createSlice({
    name:'auth',
    initialState,
    reducers:{
        setCredentials:(state:initialState, action:PayloadAction<signInCredentials>)=>{
            state.credentials = action.payload
        }
    }
})

export const {
    setCredentials
}= authSlice.actions
export default authSlice.reducer