import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { Message, Room } from "./interface";
import {
  getChatsBooker,
  getChatsLandlord,
  getRoomMessagesRequest,
  sendMessageRequest,
  deleteMessageRequest,
  markMessageAsReadRequest,
  getUserRoomsRequest,
} from "./feature";

interface ChatState {
  activeRoomId: string | null;
  messages: { [roomId: string]: Message[] };
  rooms: Room[];
  isLoading: boolean;
  error: string | null;
}

const initialState: ChatState = {
  activeRoomId: null,
  messages: {},
  rooms: [],
  isLoading: false,
  error: null,
};

export const messageSlice = createSlice({
  name: "chats",
  initialState,
  reducers: {
    setActiveRoom: (state, action: PayloadAction<string | null>) => {
      state.activeRoomId = action.payload;
    },
    setMessages: (state, action: PayloadAction<{ roomId: string; messages: Message[] }>) => {
      state.messages[action.payload.roomId] = action.payload.messages;
    },
    addMessage: (state, action: PayloadAction<Message>) => {
      const roomId = action.payload.roomId;
      if (!state.messages[roomId]) state.messages[roomId] = [];
      const existingMessageIndex = state.messages[roomId].findIndex(
        (msg) => msg.messageId === action.payload.messageId
      );
      if (existingMessageIndex === -1) {
        state.messages[roomId].push(action.payload);
      } else {
        state.messages[roomId][existingMessageIndex] = action.payload; // Update if exists
      }

      // Update last message in room
      const roomIndex = state.rooms.findIndex((room) => room.roomId === roomId);
      if (roomIndex !== -1) {
        state.rooms[roomIndex] = {
          ...state.rooms[roomIndex],
          lastMessage: action.payload.content,
          lastMessageAt: action.payload.created_at,
        };
      }
    },
    setRooms: (state, action: PayloadAction<Room[]>) => {
      state.rooms = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Get Chats Booker
      .addCase(getChatsBooker.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getChatsBooker.fulfilled, (state, action) => {
        state.isLoading = false;
        state.rooms = action.payload;
      })
      .addCase(getChatsBooker.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to fetch booker chats";
      })
      // Get Chats Landlord
      .addCase(getChatsLandlord.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getChatsLandlord.fulfilled, (state, action) => {
        state.isLoading = false;
        state.rooms = action.payload;
      })
      .addCase(getChatsLandlord.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to fetch landlord chats";
      })
      // Get Room Messages
      .addCase(getRoomMessagesRequest.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getRoomMessagesRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.messages[action.payload.roomId] = action.payload.messages;
      })
      .addCase(getRoomMessagesRequest.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to fetch messages";
      })
      // Send Message
      .addCase(sendMessageRequest.fulfilled, (state, action) => {
        const roomId = action.payload.roomId;
        if (!state.messages[roomId]) state.messages[roomId] = [];
        const existingMessageIndex = state.messages[roomId].findIndex(
          (msg) => msg.messageId === action.payload.messageId
        );
        if (existingMessageIndex === -1) {
          state.messages[roomId].push(action.payload);
        } else {
          state.messages[roomId][existingMessageIndex] = action.payload;
        }
      })
      // Delete Message
      .addCase(deleteMessageRequest.fulfilled, (state, action) => {
        const { roomId, messageId } = action.payload;
        if (state.messages[roomId]) {
          state.messages[roomId] = state.messages[roomId].map((msg) =>
            msg.messageId === messageId ? { ...msg, isDeleted: true } : msg
          );
        }
      })
      // Mark Message as Read
      .addCase(markMessageAsReadRequest.fulfilled, (state, action) => {
        const roomId = action.payload.roomId;
        if (state.messages[roomId]) {
          state.messages[roomId] = state.messages[roomId].map((msg) =>
            msg.messageId === action.payload.messageId ? action.payload : msg
          );
        }
      })
      // Get User Rooms
      .addCase(getUserRoomsRequest.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getUserRoomsRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.rooms = action.payload;
      })
      .addCase(getUserRoomsRequest.rejected, (state:any, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to fetch user rooms";
      });
  },
});

export const { setActiveRoom, setMessages, addMessage, setRooms } = messageSlice.actions;
export default messageSlice.reducer;