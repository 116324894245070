import { signal } from "@preact/signals-react";

//Replace with actual data from API and try to map it into this format, if that will be convenient for you.
export const inquiries= signal(
    [
        {
           listingId: "wq612627ws",
           name: "Large open space for meets",
           location:"Banana Island Lagos",
           image:{
            url:"/details-image-1.jpeg",
            alt: "An image of a listing in myblankspace"
           },
           startDate:{
            day:15,
            month:5,
            year: 2024
           },
           endDate: {
            day:18,
            month:11,
            year: 2024
           }
        },
        {
           listingId: "161761gsags",
           name: "Large open space for meets",
           location:"Banana Island Lagos",
           image:{
            url:"/details-image-2.jpeg",
            alt: "Another image of a listing in myblankspace"
           },
           startDate:{
            day:12,
            month:6,
            year: 2024
           },
           endDate: {
            day:12,
            month:10,
            year: 2024
           }
        },
    ]
)