import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { bookerProfile, landlordProfile } from "./interface";


interface initialState {
    landlordProfile:landlordProfile|null
    bookerProfile:bookerProfile|null
}
const initialState:initialState ={
    landlordProfile:null,
    bookerProfile:null

}

export const userSlice = createSlice({
    name:'user',
    initialState,
    reducers:{
        setBookerProfile:(state:initialState, action:PayloadAction<bookerProfile>)=>{
            state.bookerProfile = action.payload
        },
        setLandlordProfile:(state:initialState, action:PayloadAction<landlordProfile>)=>{
            state.landlordProfile = action.payload
        }


    }

})



export const {setBookerProfile,setLandlordProfile } = userSlice.actions