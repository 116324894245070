export const api_url = `${process.env.NEXT_PUBLIC_API_URL}`



export const baseUrl ={
    users:`${api_url}/users`,
    booking:`${api_url}/bookings`,
    listings:`${api_url}/listings`,
    chatBaseUrl:`${api_url}/chat`,
    payment:`${api_url}/payments`

}