import { signal } from "@preact/signals-react";

export const userChatRooms=signal(
    [
       { 
        chatRoomId:"71626272",
        name:"Crystal Palace Hotel",
        bookingId:"thh26262",
        messages: [
            {
                senderId: "643355",
                receiverId:"717881",
                message: "Hello is my reservation ready",
                createdAt:{
                    date:{day:12, month:10, year:2024},
                    time: {hours:6, minutes:33}
                }
            },
            {
                senderId: "717881",
                receiverId:"643355",
                message: "We will get back to you in two days, we apologize for the delay",
                createdAt:{
                    date:{day:12, month:10, year:2024},
                    time: {hours:6, minutes:35}
                }
            },
            {
                senderId: "643355",
                receiverId:"717881",
                message: "Alright that's fine",
                createdAt:{
                    date:{day:12, month:10, year:2024},
                    time: {hours:6, minutes:35}
                }
            },
        ]},

        //chat room 2
       { 
        chatRoomId: "210293783",
        name: "Eko Hotel and Suites",
        bookingId:"te636225",
        messages: [
            {
                senderId: "643355",
                receiverId:"717881",
                message: "When will the place be ready?",
                createdAt:{
                    date:{day:12, month:11, year:2024},
                    time: {hours:2, minutes:13}
                }
            },
            {
                senderId: "717881",
                receiverId:"643355",
                message: "Before the end of today",
                createdAt:{
                    date:{day:14, month:11, year:2024},
                    time: {hours:7, minutes:33}
                }
            },
            {
                senderId: "643355",
                receiverId:"717881",
                message: "Alright that's cool",
                createdAt:{
                    date:{day:15, month:11, year:2024},
                    time: {hours:6, minutes:33}
                }
            },
        ]}
    ]
    
)