import { createSlice } from "@reduxjs/toolkit";

interface initialStateProps {
    homePageSearchClicked: boolean;
    homePageSearchValue: string;
}

const initialState = {
    homePageSearchClicked: false,
    homePageSearchValue: ""
}

export const utilSLice = createSlice({
  name: "utils",
  initialState,
  reducers: {
    SethomePageSearchClicked(state, action) {
      state.homePageSearchClicked = action.payload;
    }
  }
})
export const {
    SethomePageSearchClicked
} = utilSLice.actions