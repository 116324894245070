import { SessionProvider } from 'next-auth/react'
import React, { FC, ReactNode } from 'react'
import AuthProvider from './authProvider'
import { SocketProvider } from './socketContext'


interface props{
    children:ReactNode
}
const GlobalProvider:FC<props> = ({children})=>{
  return (
    <>
      <SessionProvider>
          <AuthProvider>
        <SocketProvider>
            {children}
        </SocketProvider>
          </AuthProvider>
      </SessionProvider>
    </>
  )
}

export default GlobalProvider