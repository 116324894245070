import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import axios from "axios";
import type { Message, Room, ChatResponse, RoomsResponse } from "./interface";
import { baseUrl } from "../../api";

const chatBaseUrl = baseUrl.chatBaseUrl;

export const getChatsBooker = createAsyncThunk<Room[], string, { rejectValue: string }>(
  "chats/getChatsBooker",
  async (bookerId, { rejectWithValue }) => {
    try {
      const response = await axios.get<{
        message: string;
        data: {
          chats: {
            roomId: string;
            agency: any;
            listing: any;
            lastMessage?: string;
            lastMessageAt?: string;
          }[];
        };
      }>(`${baseUrl.booking}/chats/booker`);
      return response.data.data.chats.map((chat) => ({
        roomId: chat.roomId,
        participants: [bookerId, chat.agency.owner],
        name: chat.listing?.name || "Booking Chat",
        lastMessage: chat.lastMessage || "",
        lastMessageAt: chat.lastMessageAt || "",
      }));
    } catch (error) {
      return rejectWithValue("Failed to fetch booker chats");
    }
  }
);

export const getChatsLandlord = createAsyncThunk<Room[], string, { rejectValue: string }>(
  "chats/getChatsLandlord",
  async (landlordId, { rejectWithValue }) => {
    try {
      const response = await axios.get<{
        message: string;
        data: {
          chats: {
            roomId: string;
            booker: any;
            listing: any;
            lastMessage: string;
            lastMessageAt: string;
            participants: string[];
            name: string;
          }[];
        };
      }>(`${baseUrl.booking}/chats/landlord`);
      return response.data.data.chats.map((chat) => ({
        roomId: chat.roomId,
        participants: chat.participants,
        name: chat.name,
        lastMessage: chat.lastMessage,
        lastMessageAt: chat.lastMessageAt,
      }));
    } catch (error) {
      return rejectWithValue("Failed to fetch landlord chats");
    }
  }
);

export const getRoomMessagesRequest = createAsyncThunk<
  ChatResponse,
  { userId: string; roomId: string },
  { rejectValue: string }
>("chats/getRoomMessages", async ({ userId, roomId }, { rejectWithValue }) => {
  try {
    const response = await axios.get<ChatResponse>(`${chatBaseUrl}/room/${roomId}/messages`);
    return response.data;
  } catch (error) {
    return rejectWithValue("Failed to fetch room messages");
  }
});

export const sendMessageRequest = createAsyncThunk<
  Message,
  { userId: string; roomId: string; content: string },
  { rejectValue: string }
>("chats/sendMessage", async ({ roomId, content }, { rejectWithValue }) => {
  try {
    const response = await axios.post<Message>(`${chatBaseUrl}/message`, {
      roomId,
      content,
    });
    return response.data;
  } catch (error) {
    return rejectWithValue("Failed to send message");
  }
});

export const deleteMessageRequest = createAsyncThunk<
  { messageId: string; roomId: string },
  { userId: string; messageId: string; roomId: string },
  { rejectValue: string }
>("chats/deleteMessage", async ({ messageId, roomId }, { rejectWithValue }) => {
  try {
    await axios.delete(`${chatBaseUrl}/message/${messageId}`);
    return { messageId, roomId };
  } catch (error) {
    return rejectWithValue("Failed to delete message");
  }
});

export const markMessageAsReadRequest = createAsyncThunk<
  Message,
  { userId: string; messageId: string },
  { rejectValue: string }
>("chats/markMessageAsRead", async ({ messageId }, { rejectWithValue }) => {
  try {
    const response = await axios.post<Message>(`${chatBaseUrl}/message/${messageId}/read`, {});
    return response.data;
  } catch (error) {
    return rejectWithValue("Failed to mark message as read");
  }
});

export const getUserRoomsRequest = createAsyncThunk<Room[], string, { rejectValue: string }>(
  "chats/getUserRooms",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axios.get<RoomsResponse>(`${chatBaseUrl}/rooms`);
      return response.data.rooms;
    } catch (error) {
      return rejectWithValue("Failed to fetch user rooms");
    }
  }
);
