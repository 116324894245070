import { signal } from "@preact/signals-react";

//Replace with actual data from API and try to map it into this format, if that will be convenient for you.
//For now I just hand coded values into a preact signal
export const bookings= signal([
    {
       bookingId: "te636225",
       listingId:"wq612627ws",
       name: "Large open space for meets",
       location:"Banana Island Lagos",
       price:30000,
       image:{
        url:"/details-image-1.jpeg",
        alt: "An image of a listing in myblankspace"
       },
       startDate:{
        day:15,
        month:9,
        year: 2024
       },
       endDate: {
        day:18,
        month:3,
        year: 2025
       },
       amenities:[
        {
            name: "Security",
            price: 12000,
        },
        {
            name: "Generator",
            price: 6000,
        },
        {
            name: "Cleaning",
            price: 10000,
        },

    ]
    },
    {
       bookingId: "thh26262",
       listingId: "161761gsags",
       name: "Large open space for meets",
       location:"Banana Island Lagos",
       price:45000,
       image:{
        url:"/details-image-2.jpeg",
        alt: "Another image of a listing in myblankspace"
       },
       startDate:{
        day:12,
        month:9,
        year: 2024
       },
       endDate: {
        day:12,
        month:3,
        year: 2025
       },
       amenities:[
        {
            name: "Security",
            price: 12000,
        },
        {
            name: "Generator",
            price: 6000,
        },
        {
            name: "Cleaning",
            price: 10000,
        },
        {
            name: "Caterer",
            price: 10000,
        },

    ]
    },
  ])