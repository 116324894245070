"use client";
import { createContext, useContext, useEffect, useState, ReactNode } from "react";
import { io, Socket } from "socket.io-client";
import { useDispatch } from "react-redux";
import { useSession } from "next-auth/react";
import { setMessages, addMessage } from "@/src/redux/listings/messageSlice";
import type { Message } from "@/src/redux/listings/messageSlice/interface";
import { useAppSelector } from "@/src/redux/store";

interface SocketContextType {
  socket: Socket | null;
  connectSocket: () => void;
  disconnectSocket: () => void;
  sendMessage: (payload: { room: string; content: string; messageType?: string }) => void;
}

const SocketContext = createContext<SocketContextType | undefined>(undefined);

export const SocketProvider = ({ children }: { children: ReactNode }) => {
  const { data: session } = useSession();
  const token = session?.user.token as string;
  const dispatch = useDispatch();
  const [socket, setSocket] = useState<Socket | null>(null);

  const connectSocket = () => {
    if (!socket && token) {
      const newSocket = io(process.env.NEXT_PUBLIC_API_URL, {
        auth: { token },
      });

      newSocket.on("connect", () => {
        console.log("Socket connected:", newSocket.id);
      });

      newSocket.on("roomMessages", (messages: Message[]) => {
        const roomId = messages.length > 0 ? messages[0].roomId : "";
        if (roomId) {
          console.log(`Received initial messages for room ${roomId}:`, messages);
          dispatch(setMessages({ roomId, messages }));
        } else {
          console.warn("No valid roomId found in roomMessages event");
        }
      });

      newSocket.on("receiveMessage", (message: Message) => {
        console.log("Received new message:", message); // Immediate log
        dispatch(addMessage(message)); // Update Redux state
      });

      newSocket.on("disconnect", () => {
        console.log("Socket disconnected");
      });

      newSocket.on("connect_error", (err) => {
        console.error("Socket connection error:", err.message);
      });

      newSocket.on("error", (error) => {
        console.error("Socket error:", error.message);
      });

      setSocket(newSocket);

      return () => {
        newSocket.off("connect");
        newSocket.off("roomMessages");
        newSocket.off("receiveMessage");
        newSocket.off("disconnect");
        newSocket.off("connect_error");
        newSocket.off("error");
      };
    }
  };

  const disconnectSocket = () => {
    if (socket) {
      socket.disconnect();
      console.log("Socket manually disconnected");
      setSocket(null);
    }
  };

  const sendMessage = (payload: { room: string; content: string; messageType?: string }) => {
    if (socket && session?.user?.id) {
      const message = {
        senderId: session.user.id,
        room: payload.room,
        content: payload.content,
        messageType: payload.messageType || "text",
      };
      console.log("Sending message:", message); // Immediate log
      socket.emit("sendMessage", message);
    } else {
      console.warn("Cannot send message: socket not connected or user not authenticated");
    }
  };

  useEffect(() => {
    if (token && !socket) {
      const cleanup = connectSocket();
      return cleanup;
    }
    return () => {
      disconnectSocket();
    };
  }, [token]);

  return (
    <SocketContext.Provider value={{ socket, connectSocket, disconnectSocket, sendMessage }}>
      {children}
    </SocketContext.Provider>
  );
};

export const ChatComponent = ({ activeRoomId }: { activeRoomId: string }) => {
  const roomMessages = useAppSelector((state) => state.chats.messages[activeRoomId] || []);

  useEffect(() => {
    if (roomMessages.length > 0) {
      console.log("Current messages in room:", roomMessages);
    }
  }, [roomMessages]);

  return (
    <div>
      {roomMessages.map((msg) => (
        <div key={msg.messageId}>{msg.content}</div>
      ))}
    </div>
  );
};

export const useSocket = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error("useSocket must be used within a SocketProvider");
  }
  return context;
};