import { emit } from "@/src/hooks/useListener";
import { ALERT_EVENTS } from "@/src/lib/constants";
import axios, { AxiosError } from "axios";
import { setListing, setListings, setPublicListings } from ".";
import { baseUrl } from "../api";
import { Dispatch } from "redux";
import { z } from "zod";
import { uploadListingFormSchema } from "@/src/lib/schemas/listings";

import { base64ToFile, objectToFormData } from "@/src/lib/functions";
import { AppImage } from "@/src/components/page/dashboard/landlords/listings/upload/add-images";
import { FilterState } from "./interface";



export enum PRICE_INTERVAL {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}
export enum SIZE_UNIT {
  SQ_FT = 'sq_ft',
  SQ_M = 'sq_m',
}

export enum CURRENCY {
  USD = 'USD',
  NGN = 'NGN',
}

export enum LISTING_TYPE {
  EVENT_CENTER = 'event_center',
  RETAIL = 'retail',
}

export enum LISTING_VERIFICATION_STATUS {
  NONE = 'none',
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export enum LISTING_QUALITY {
  STANDARD = 'standard',
  PREMIUM = 'premium',
  ICONIC = 'iconic',
}

export enum LISTING_RATINGS {
  EXCELLENT = 'excellent',
  GOOD = 'good',
  AVERAGE = 'average',
  BAD = 'bad',
}

export const LISTING_RATINGS_MAP = {
  [LISTING_RATINGS.EXCELLENT]: 5,
  [LISTING_RATINGS.GOOD]: 4,
  [LISTING_RATINGS.AVERAGE]: 3,
  [LISTING_RATINGS.BAD]: 2,
};

export enum PUBLIC_LISTING_CATEGORIES {
  LATEST = 'latest',
  POPULAR = 'popular',
  NEAREST = 'nearest',
  RETAIL = 'retail',
  EXPERIENCES = 'experiences',
  EVENT_CENTER = 'event_center',
  PREMIUM = 'premium',
  ICONIC = 'iconic',
  ALL = 'all',
}



interface Range {
  from?: string;
  to?: string;
}

// Interface equivalent to ListingFilters
interface ListingFilters {
  location?: string;
  rating?: LISTING_RATINGS;
  use_cases?: string[];
  amenities?: string[];
  price?: Range;
  size?: Range;
  verification_status?: LISTING_VERIFICATION_STATUS;
}

// Interface equivalent to ListingPagination
interface ListingPagination {
  per_page?: number;
  page?: number;
}

// Interface equivalent to PublicListingQueryDto
export interface ListingParams {
  location?: string;
  filters?: FilterState;
  pagination?: ListingPagination;
  search?: string;
  category?: PUBLIC_LISTING_CATEGORIES;
}

export enum BOOKING_STATUS {
  PENDING = 'pending',
  PAID = 'paid',
  APPROVED = 'approved',
  IN_USE = 'in_use',
  REJECTED = 'rejected',
  CANCELLED = 'cancelled',
  COMPLETED = 'completed',
}
export interface TimeRange {
  start_time?: string;
  end_time?: string;
}

export interface Availability {
  alwaysAvailable: boolean;
  timeRange?: TimeRange;
}

export interface createLisiting {
  description: string;
  additional_information:string,
  name: string;
  input_address: string;
  formatted_address: string;
  tags?: string[];
  type:string;
  use_cases?: string[];
  base_price: number;
  base_price_interval: string;
  size: number;
  extras?: {
    amenities?: string[];
    accessibility?: string[];
    features?: {
      name: string;
      description: string;
      price: number;
      interval: string;
    }[];
  };
  images: AppImage[];
  variable_pricing?: Record<string, string>;
  availability?: Availability; // Add availability field

}

interface updateLisiting extends createLisiting {
  id: string;
 
}


export const getPublicListingsRequest = 
  (params?: ListingParams) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: "LISTINGS_LOADING", payload: true });
      const response = await axios.get(`${baseUrl.listings}/public`, { params });
      return dispatch(setPublicListings(response.data));
    } catch (error: unknown) {
      const axiosError = error as AxiosError|any;
      console.error("Error fetching listings:", axiosError.message);
      emit(ALERT_EVENTS.ERROR, axiosError.response?.data?.message || "An error occurred");
      dispatch({ type: "LISTINGS_ERROR", payload: axiosError.message });
    } finally {
      dispatch({ type: "LISTINGS_LOADING", payload: false });
    }
};


    
export const getListingsRequest=()=>async(dispatch:Dispatch)=>{
        try {
            const response = await axios.get(`${baseUrl.listings}`)
            dispatch(setListings(response.data))
        } catch (error) {
            emit(ALERT_EVENTS.ERROR, error)
        }
}

export const createListingRequest = (data: createLisiting) => async (dispatch: Dispatch) => {
  try {
    const { images, ...rest } = data;

    // Convert images to files (handling your existing logic)
    const imgs = images
      .map((img: AppImage) => (img.src ? base64ToFile(img.src, img.name || "image.jpg") : null))
      .filter((file): file is File => file !== null);

    // Create FormData object
    const formData = new FormData();

    // Normalize use_cases to an array
    const normalizedUseCases = Array.isArray(rest.use_cases)
      ? rest.use_cases
      : rest.use_cases
        ? [rest.use_cases]
        : []; // Default to empty array if undefined

    const normalizedData = {
      ...rest,
      use_cases: normalizedUseCases,
    };

    // Append data to FormData
    for (const key in normalizedData) {
      if (Object.prototype.hasOwnProperty.call(normalizedData, key)) {
        let value = normalizedData[key as keyof typeof normalizedData];

        if (key === 'use_cases' && Array.isArray(value)) {
          // Append each use case with an indexed key
          value.forEach((useCase: string, index: number) => {
            formData.append(`use_cases[${index}]`, useCase);
          });
        } else if (['extras', 'variable_pricing', 'availability'].includes(key)) {
          // Append as JSON strings for complex objects
          formData.append(key, JSON.stringify(value));
        } else {
          // Append primitive values directly
          formData.append(key, value as string);
        }
      }
    }

    // Append images
    imgs.forEach((file: File, index: number) => {
      formData.append(`images[${index}]`, file);
    });

    // Send the request
    const response = await axios.post(`${baseUrl.listings}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return [response.data, null]; // Success
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || error.message || "An error occurred";
    return [null, errorMessage]; // Error
  }
};

    
    

    export const getListingByIDRequest =(data:string)=>async(dispatch:Dispatch)=>{
      try {
        console.log(data);
        
        const response = await axios.get(`${baseUrl.listings}/${data}`);
        dispatch(setListing(response.data))
        console.log(response.data);
        
       
        return [response.data,null ]
        
      } catch (error:any) {
        const errorMessage = error.response?.data?.message || error.message || "An error occurred";
        emit(ALERT_EVENTS.ERROR, errorMessage);
    
        return [null, errorMessage]; // 
      }
    }

    export const requestReviewRequest = 
    (data: { id: string; docs: File[] }) => 
    async (dispatch: Dispatch) => {
      try {
        const { docs, id } = data;
  
        // Initialize FormData and append files with proper keys (files[0], files[1], ...)
        const formData = new FormData();
        docs.forEach((file: File, index: number) => {
          formData.append(`files[${index}]`, file);
        });
  
        // Perform the API request
        const response = await axios.put(
          `${baseUrl.listings}/review/${id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
  
        // Return the response data and null error
        return [response.data, null];
      } catch (error: any) {
        // Extract error message
        const errorMessage = error.response?.data?.message || error.message || "An error occurred";
  
        // Emit an error event
        emit(ALERT_EVENTS.ERROR, errorMessage);
  
        // Return null data and the error message
        return [null, errorMessage];
      }
    };

    // export const getListingByIDRequest

    export const updateListingRequest = (data: updateLisiting) => async (dispatch: Dispatch) => {
      try {
        const { images, ...rest } = data;
    
        // Convert images to files, handle undefined src
        const imgs = images.map((img: AppImage) => {
          if (img.src) {
            return base64ToFile(img.src, img.name || "image.jpg");
          } else {
            // If src is undefined, you might want to skip this image or use a placeholder
            console.warn('Image source is undefined, skipping or using a default image');
            return null; // or some default image file
          }
        }).filter((file): file is File => file !== null); // Filter out null values
    
        // Create FormData object
        const formData = new FormData();
    
        // Append rest of the data as JSON strings or array elements
        for (const key in rest) {
          if (Object.prototype.hasOwnProperty.call(rest, key)) {
            const value = rest[key as keyof typeof rest];
    
            if (key === 'use_cases' && Array.isArray(value)) {
              value.forEach((useCase: string, index: number) => {
                formData.append(`use_cases[${index}]`, useCase);
              });
            } else if (key === 'extras' && value !== undefined) {
              formData.append(key, JSON.stringify(value));
            } else if (key === 'base_price_interval') {
              // Append base_price_interval as a string directly
              formData.append(key, value as string);
            } else {
              formData.append(key, JSON.stringify(value));
            }
          }
        }
    
        // Append images to FormData
        imgs.forEach((file: File, index: number) => {
          formData.append(`images[${index}]`, file);
        });
    
        // Make the API request
        const response = await axios.put(`${baseUrl.listings}/${data.id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
    
        return [response.data, null]; // Return response and no error
      } catch (error: any) {
        const errorMessage = error.response?.data?.message || error.message || "An error occurred";
        emit(ALERT_EVENTS.ERROR, errorMessage);
    
        return [null, errorMessage]; // Return no response and the error
      }
    }

    export const removeListingsRequest =(data:string)=>async( dispatch:Dispatch)=>{
      try{
        const response = await axios.delete(`${baseUrl.listings}/${data}`);
        return true
      }catch(error:any){
        return false
      }
    }

