import { useSession } from "next-auth/react";
import { Provider } from "react-redux";
import { store } from "@/src/redux/store";
import setAuthToken from "@/src/lib/setAccessToken";
import { Toaster } from "@/src/components/ui/toaster";
import React, { FC, ReactNode, useEffect, useState } from "react";
import ProgressBar from "../loading";

interface Props {
  children: ReactNode;
}

const AuthProvider: FC<Props> = ({ children }) => {
  const { data: session, status } = useSession();
  const [isReady, setIsReady] = useState(false);

  /**
   |--------------------------------------------------
   | Set Auth Token after session is authenticated
   |--------------------------------------------------
   */
  useEffect(() => {
    if (status === "authenticated" && session?.user.token) {
      setAuthToken(session.user.token); 
      setIsReady(true); 
    } else if (status === "unauthenticated") {
      setAuthToken(null);
      setIsReady(true); 
    }
  }, [session, status]);


  if (!isReady || status === "loading") {
    return <ProgressBar/>; 
  }

  return (
    <Provider store={store}>
      {children}
      <Toaster />
    </Provider>
  );
};

export default AuthProvider;
